.cr-slider {
    background-color: #ffffff!important;
}
.makeWhite {
    background-color: #ffffff!important;
}
.logoUpload {
    max-height: 150px;
    height: auto;
    max-width: 150px;
    width: auto;
}
.logo-brand {
    font-size: 12px;
    text-align: left;
    font-weight: bold;
}





.croppie-box {
    /*position: relative;*/
    cursor: pointer;
    width: 250px;
    height: 250px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px !important;
    position: absolute;
    cursor: pointer;
}

.dropbox:hover {
    color: #000000;
    background: rgba(101, 222, 100, 0.6); /* when mouse over to the drop zone, change color */
}


