.tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 2em;
    .tabsBtn {
        color: #256c34;
        font-size: 1.5rem;
        font-weight: 700;
        border: none;
        border-bottom: 0.2em solid #fff;
        padding: 1em;
        text-align: center;
        background-color: inherit;
        &:hover {
            cursor: pointer;
        }
        &:focus {
            outline: none;
            border-bottom: 0.2rem solid #6ca900;
            color: #6ca900;
        }
    }
    .active {
        outline: none;
        border-bottom: 0.2rem solid #6ca900;
        color: #6ca900;
    }
}