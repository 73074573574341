.dash-icon {
    height: 5em;
    width: 7em;
}

.dashboard {
    .count {
        font-size: 3.5em;
        float: right;
        color: rgb(175, 173, 173);
    }
    .loader {
        width: 10%;
        margin: auto;
    }
}