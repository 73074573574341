.u-margin-bottom-big {
    margin-bottom: 8rem !important;
    @include respond(tab-port) {
        margin-bottom: 5rem !important;
    }
}

.u-margin-bottom-huge {
    margin-bottom: 10rem !important;
    @include respond(tab-port) {
        margin-bottom: 7rem !important;
    }
}

.u-margin-top-big {
    margin-top: 8rem !important;
    @include respond(tab-port) {
        margin-top: 5rem !important;
    }
}

.u-margin-top-huge {
    margin-top: 10rem !important;
    @include respond(tab-port) {
        margin-top: 7rem !important;
    }
}

.u-margin-bottom-medium {
    margin-bottom: 4rem !important;
    @include respond(tab-port) {
        margin-bottom: 3rem !important;
    }
}

.u-margin-bottom-small {
    margin-bottom: 1.5rem !important;
}

// Round Edge
.rounded {
    border-radius: 10px !important;
}

// Center text
.center-text {
    text-align: center !important;
}

// Margins
.m-mb-3 {
    margin-bottom: 3rem;
}

// Width
.width-10 {
    width: 10% !important;
}

.width-20 {
    width: 20% !important;
}

.width-30 {
    width: 30% !important;
}

.width-40 {
    width: 40% !important;
}

.width-50 {
    width: 50% !important;
}

.width-60 {
    width: 60% !important;
}

.width-70 {
    width: 70% !important;
}

.width-80 {
    width: 80% !important;
}

.width-90 {
    width: 90% !important;
}

.width-100 {
    width: 100% !important;
}

// Height
.height-10 {
    height: 10vh !important;
}

.height-20 {
    height: 20vh !important;
}

.height-30 {
    height: 30vh !important;
}

.height-40 {
    height: 40vh !important;
}

.height-50 {
    height: 50vh !important;
}

.height-60 {
    height: 60vh !important;
}

.height-70 {
    height: 70vh !important;
}

.height-80 {
    height: 80vh !important;
}

.height-90 {
    height: 90vh !important;
}

.height-100 {
    height: 100vh !important
}

// text colors
.text-primary {
    color: $color-primary !important;
}

.m-text-primary-2 {
    color: $color-primary-light-3;
}

.m-text-grey {
    color: $color-grey;
}

// background
.m-bg-primary {
    background-color: $color-primary-light;
}

.m-bg-blue {
    background-color: $color-secondary-light-2;
}

// headings
.m-h2 {
    font-weight: 900;
    color: $color-black;
    line-height: 1;
}