        // Buttons
        .min-btn {
            &:link,
            &:visited {
                font-size: $default-font-size;
                text-transform: uppercase;
                text-decoration: none;
                padding: 1.5rem 4rem;
                display: inline-block;
                border-radius: 10rem;
                transition: all 0.8s;
                position: relative;
                // Change for <button>
                border: none;
                cursor: pointer;
            }
            &:hover {
                transform: translateY(-0.3rem);
                box-shadow: 0 1rem 2rem rgba($color-black, 0.2);
                &::after {
                    transform: scaleX(1.4) scaleY(1.6);
                    opacity: 0;
                }
            }
            &:active,
            &:focus {
                outline: none;
                transform: translate(-0.1rem);
                box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2);
            }
            &--white {
                background-color: $color-white;
                color: $color-grey-dark-1;
                &::after {
                    background-color: $color-white;
                }
            }
            &--green {
                background-color: $color-primary;
                color: $color-white;
                &::after {
                    background-color: $color-primary;
                }
            }
            /* pseudo element */
            &::after {
                content: '';
                display: inline-block;
                height: 100%;
                width: 100%;
                border-radius: 10rem;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                transition: all 0.4s;
            }
            &--animated {
                animation: moveInBottom 5s ease-out 0.75s;
                animation-fill-mode: backwards;
            }
        }
        
        .m-btn-text {
            &:link,
            &:visited {
                font-size: $default-font-size;
                text-transform: uppercase;
                text-decoration: none;
                padding: 1.2rem 4rem;
                line-height: 1;
                display: inline-block;
                border-radius: 10rem;
                transition: all 0.3s;
                position: relative;
                // Change for <button>
                border: none;
                cursor: pointer;
            }
            &:hover {
                transform: translateY(-0.3rem);
                box-shadow: 0 1rem 2rem rgba($color-black, 0.2);
                &::after {
                    transform: scaleX(1.4) scaleY(1.6);
                    opacity: 0;
                }
            }
            &:active,
            &:focus {
                outline: none;
                transform: translate(-0.1rem);
                box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2);
            }
            &--white {
                background-color: $color-white;
                color: $color-grey-dark-1;
                // &::after {
                //   background-color: $color-white;
                // }
            }
            &--green {
                background-color: $color-primary;
                color: $color-white;
                // &::after {
                //   background-color: $color-primary;
                // }
            }
            /* pseudo element */
            // &::after {
            //   content: '';
            //   display: inline-block;
            //   height: 100%;
            //   width: 100%;
            //   border-radius: 10rem;
            //   position: absolute;
            //   top: 0;
            //   left: 0;
            //   z-index: -1;
            //   transition: all 0.4s;
            // }
            // &--animated {
            //   animation: moveInBottom 5s ease-out 0.75s;
            //   animation-fill-mode: backwards;
            // }
        }
        
        .m-btn-text {
            &:link,
            &:visited {
                font-size: $default-font-size;
                color: $color-primary;
                display: inline-block;
                text-decoration: none;
                border-bottom: 1px solid $color-primary;
                padding: 0.3rem;
                transition: all 0.3s ease-in;
            }
            &:hover {
                background-color: $color-primary;
                color: $color-white;
                box-shadow: 0 1rem 2rem rgba($color-black, 0.15);
                transform: translateY(-2px);
            }
            &:active {
                box-shadow: 0 0.5rem 1rem rgba($color-black, 0.15);
                transform: translateY(0);
            }
        }
        
        .m-btn-primary {
            background-color: $color-primary-light;
        }
        
        .m-btn-secondary {
            background-color: $color-secondary;
        }
        
        .m-btn-outline-primary {
            background: white;
            color: $color-primary;
            border: 0.13em solid $color-primary-light;
        }
        
        .m-btn {
            color: $color-white;
            line-height: 2;
            padding: 0.4em 0.63em;
            text-align: center;
            border: none;
            &:focus {
                outline: none;
            }
        }
        
        .m-btn-round {
            border-radius: 2.3rem;
        }
        
        .m-btn-transparent {
            background-color: transparent;
        }
        
        .m-text-white {
            color: $color-white;
        }
        
        .m-btn-white {
            background-color: $color-white;
            color: $color-black;
        }
        
        .m-btn-small-round {
            border-radius: .5rem;
        }
        
        .m-btn-border-white {
            border: 2px solid $color-white;
        }
        
        .m-btn-bg-white {
            background-color: $color-white;
            color: black
        }
        
        .m-poiter {
            &:hover {
                cursor: pointer !important;
            }
        } // Button Group Input
        .button-input-group {
            display: flex;
            border: 1px solid $color-primary-light;
            border-radius: 0.3rem;
            overflow: hidden;
            &__btn {
                font-size: 1.8rem;
                color: $color-primary-light;
                font-weight: 600;
                background-color: $color-grey-light-1;
                padding: 0.5rem 1.3rem;
                display: inline-block;
                transition: all 0.2s;
                position: relative;
                // Change for <button>
                border: none;
                cursor: pointer;
                &:hover {
                    background-color: $color-primary-light;
                    color: $color-white;
                    box-shadow: 0 1rem 3rem rgba($color-black, 0.2);
                }
                &:focus {
                    outline: none;
                }
            }
            &__input {
                outline: none;
                border: none;
                width: 8rem;
                padding: 0 2rem;
                &:focus {
                    outline: none;
                }
            }
        }
        
        // Color Select Group
        .color-select-group {
            display: flex;
            align-items: center;
            .selected-color-box {
                width: 2rem;
                height: 2rem;
                margin: 0 2rem;
            }
        }
        
        .m-react-select-container {
            width: 15rem;
            margin-right: 3rem;
        }
        
        .react-select {
            &__control {
                &:focus {
                    outline: none;
                    border-color: none;
                }
                width: 100%;
            }
            // &____value-container {}
            &____indicators {
                &:focus {
                    outline: none;
                    border-color: none;
                }
            }
            &__menu {
                background-color: orangered;
            }
            &__menu-list {
                background-color: $color-primary-light;
                color: $color-white;
            }
            &__option {
                background-color: palegreen;
            }
        }