.list-product {
    h4 {
        color: #256C34;
        font-size: 1.5em;
        font-weight: 800;
        margin-bottom: -10px;
    }
    i {
        font-size: 2em;
    }
    .btn-group {
        button {
            font-size: 1em !important;
        }
    }
    .dropdown-menu {
        .dropdown-item {
            font-size: 1.5em;
        }
    }
    input {
        font-size: 1em;
    }
}

.create-product {
    input {
        font-size: 1em;
    }
    select {
        font-size: 1em;
        &>option {
            color: black;
        }
    }
    .uploadimage {
        position: relative;
        small {
            font-size: 1.6em !important;
        }
    }
}

.cr-image {
    width: 100%;
    height: 100%;
}

.croppie-controls {
    button {
        font-size: 1em !important;
    }
}

.croppie-box {
    /*position: relative;*/
    cursor: pointer;
    width: 250px;
    height: 250px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.dropbox {
    outline-offset: -10px;
    /*background: rgba(6, 68, 154, 0.4) !important;*/
    color: #000000;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='56' ry='56' stroke='%2351BE50FF' stroke-width='8' stroke-dasharray='50%25%2c 13%25' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
    border-radius: 56px;
    padding: 10px 10px;
    /*outline: 3px dashed grey; !* the dash box *!*/
    /*outline-offset: -10px;*/
    /*background: #51BE50;*/
    position: relative;
    cursor: pointer;
    width: 250px;
    height: 200px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @include respond(phone) {
        width: 100% !important;
        height: 10em !important;
    }
}

.react-dropdown-tree-select {
    .dropdown {
        .dropdown-content {
            height: 200px;
            overflow-y: scroll;
        }
    }
}

.product-details {
    p {
        font-size: 1em;
    }
    button {
        font-size: 1em;
    }
}