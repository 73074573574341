.branch-setup {
    p {
        font-size: 1.5rem;
    }
}

.main-header {
    p {
        font-weight: 800;
        color: $color-primary;
        font-size: 2.3rem;
    }
}