// Font
@font-face {
    font-family: 'Nunito';
    src: local('MyFont'), url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;600;700;800;900&display=swap');
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: 'inherit';
}

html {
    font-size: 62.5%; // 1rem = 10px
    font-family: 'Nunito' san-serif;
    @include respond(tab-land) {
        font-size: 56.25%; //1rem = 9px, 9/16 = 56.25%
    }
    @include respond(tab-port) {
        font-size: 50%; //1rem = 8px, 8/16 = 50%
    }
    @include respond(phone) {
        font-size: 50%; //1rem = 8px, 8/16 = 50%
    }
    @include respond(big-desktop) {
        font-size: 75%; //1rem = 12px, 12/16 = 75%
    }
}

body {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    @include respond(tab-port) {
        padding: 0;
    }
}

::selection {
    background-color: $color-primary;
    color: $color-white;
}