body {
    font-family: 'Nunito', sans-serif;
    font-weight: 200;
    line-height: 1.6;
    font-size: 1.6em !important;
    color: $color-grey-dark-1;
}

::placeholder {
    font-size: 1.4rem;
}

select>option {
    color: red;
}

.min-container {
    padding: 5rem;
    width: 100%;
}

.heading-primary {
    font-size: 3rem;
    font-weight: 500;
    text-transform: uppercase;
}

.heading-secondary {
    font-size: 2rem;
    font-weight: 700;
    text-transform: capitalize;
}

.heading-tertiary {
    font-size: 1.6rem;
    font-weight: 700;
    color: $color-grey-dark-1;
}

.heading-four {
    font-size: 1.5rem;
    font-weight: 00;
}

.header-4 {
    font-size: 1.6rem;
    font-weight: 600;
}

.m-text-primary {
    color: $color-primary-light;
}

.m-text-primary-dark {
    color: $color-primary;
}

.m-text-secondary {
    color: $color-secondary;
}

.m-text-blue {
    color: $color-blue;
}

.m-text-white {
    color: $color-white;
}

.paragraph {
    font-size: 1.6rem;
    &:not(:last-child) {
        margin-bottom: 0.3rem;
    }
}