.m-table {
  border-collapse: collapse;
  width: 100%;
  border: none;

  th,
  td {
    text-align: left;
    padding: 1rem;
  }
}

.th-text {
  color: $color-grey-dark-2;
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 2rem;
}
