// navbar mobile
.navbar {
    margin: auto;
    .nav-item {
        .nav-link {
            font-weight: 700;
            color: #2A6F39 !important;
            margin-left: 2.5em;
        }
    }
}

// Side Bar styles
.side-nav {
    // max-width: 100%;
    position: sticky;
    top: 0;
    max-width: 20em;
    min-width: 15em;
    margin: 0 0 0 3.125em;
    background-color: white;
    -webkit-box-shadow: 0.3125em 0.625em 1.125em #ebe9e9;
    box-shadow: 0.3125em 0.625em 1.125em #ebe9e9;
    border-radius: 0.625em;
    font-weight: 500;
    padding: 0 !important;
    font-size: 1.8rem;
    @include respond(phone) {
        width: 83%;
        margin-left: 3.2em;
    }
    .nav-head {
        padding-left: 0.625em;
        padding-top: 0.9375em;
        margin-top: 0.625em;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @include respond(phone) {
            flex-direction: row;
            flex-wrap: nowrap;
        }
        img {
            width: 5em;
            height: 5em;
            border-radius: 100%;
        }
        div {
            p {
                font-weight: 500;
                font-size: 1.9rem;
                span {
                    font-size: 1.5rem;
                    font-weight: 400;
                }
            }
        }
    }
    hr {
        background-color: #6d6969;
        opacity: 0.15;
    }
    ul {
        list-style-type: none;
        li {
            border-bottom: 0.06em solid $color-grey-light-2;
            width: 100%;
            padding: 1.6em 0 1.6em 1.9em;
            padding-left: 1.9em;
            font-size: 1.6rem;
            a {
                text-decoration: none;
                color: $color-primary-light-3;
            }
            &._active {
                background-color: $color-primary-light-3;
                font-weight: bold;
                a {
                    color: $color-white;
                }
                img {
                    -webkit-filter: brightness(0) invert(2);
                    filter: brightness(0) invert(2);
                }
                span {
                    color: $color-white;
                }
            }
            img {
                margin-right: 1.3em;
                margin-top: 0.63em;
            }
            &:active {
                background-color: $color-primary-light-3;
                cursor: pointer;
                a {
                    color: $color-white;
                }
                img {
                    -webkit-filter: brightness(0) invert(2);
                    filter: brightness(0) invert(2);
                }
            }
        }
    }
}

// Footer styles
.footer {
    width: 100%;
    height: auto;
    background-color: $color-secondary-light-2;
    opacity: 0.8;
    .content {
        width: 95%;
        padding-top: 1rem;
        margin: auto;
        .footer-head {
            border: none;
            border-bottom: 0.13em solid $color-white;
            p {
                color: $color-white;
                font-weight: 700;
                font-size: 1.4rem;
            }
        }
        .footer-link {
            display: block;
            text-decoration: none;
            font-weight: 400;
            line-height: 2.2em;
            font-size: 1.3rem;
            color: $color-white;
            &:hover {
                color: $color-white;
            }
        }
        .contact {
            h3 {
                color: $color-white;
                font-weight: 900;
                font-size: 1.4em;
                font-family: Poppins;
                @include respond(phone) {
                    text-align: center;
                }
            }
            h4 {
                color: $color-white;
                font-size: 1.2em;
                font-weight: 600;
            }
        }
        .social {
            h3 {
                color: $color-social;
                font-weight: 900;
                font-size: 1.5em;
                text-align: center;
                font-family: Poppins;
            }
            a {
                color: $color-social;
                &:hover {
                    color: $color-social;
                }
            }
        }
        .footer-right {
            width: 100%;
            height: 30em;
            border-left: 0.13em solid #0F5CD0;
            padding-left: 2em;
            @include respond(phone) {
                padding-left: 0;
                border: none;
            }
            .logo-container {
                img {
                    width: 3.1em;
                    height: 3em;
                }
                p {
                    font-family: Poppins;
                    font-size: 1.25em;
                    font-weight: 900;
                }
            }
            button {
                background-color: $color-primary-light-3;
            }
        }
        .social-box {
            padding-top: 2.2em;
            margin-top: 1rem;
            width: 100%;
            display: flex;
            justify-content: space-around;
            border-left: 0.13em solid #0F5CD0;
            @include respond(phone) {
                border: none;
            }
        }
    }
}

// Dashboard Header
.dashboard-header {
    .top {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        padding-top: 0.9em;
        padding-left: 1em;
        background-color: white;
        button {
            border: none;
            background-image: linear-gradient(#8ADF39 34.8%, #50BC7A 100.11%);
            padding: 0.63em;
            border-radius: 19em;
            color: white;
            margin-right: 2em;
            float: right;
            font-size: 1.5rem;
        }
    }
    .head {
        position: sticky !important;
        top: 0;
        padding: 1.25em;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        background-color: white;
        -webkit-box-shadow: 0.063em 0.19em 0.25em #dddcdc;
        box-shadow: 0.063em 0.19em 0.25em #dddcdc;
        width: 100%;
        .logo {
            img {
                width: 9em;
                height: 2.8em;
                margin-top: 0.3em;
                margin-left: 0.63em;
            }
        }
        .toggle {
            margin-right: 6.25em;
            margin-top: 0.63em;
            margin-left: 1.9em;
            width: 2%;
            @include respond(phone) {
                margin-right: 3em;
                margin-left: 0;
            }
            &:hover {
                cursor: pointer;
            }
            span.one {
                position: absolute;
                width: 2.5em;
                height: 0.3em;
                background: black;
                border-radius: 1.25em;
            }
            span.two {
                position: absolute;
                width: 2.5em;
                height: 0.25em;
                background: black;
                border-radius: 1.25em;
                margin-top: 0.75em;
            }
            span.three {
                position: absolute;
                width: 2.5em;
                height: 0.25em;
                background: black;
                border-radius: 1.25em;
                margin-top: 1.4em;
            }
        }
        .search-div {
            width: 70%;
            @include respond(phone) {
                width: 100% !important;
                margin-right: .3em !important;
            }
            .srch-form {
                position: relative;
                img {
                    position: absolute;
                    top: 0.9em;
                    left: 0.9em;
                }
                input {
                    border: none;
                    width: 100%;
                    height: 3.5em;
                    border-radius: 0.44em;
                    background-color: #F2F3F7;
                    padding-left: 2.5em;
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
        .myacct-btn {
            background-color: $color-secondary;
            color: $color-white;
            font-weight: 600;
            border: none;
            border-radius: 2.5em;
            line-height: 2;
            font-size: 1em;
            &:hover {
                color: $color-white;
            }
            ;
            .acct {
                background-color: $color-white;
                color: $color-primary-light;
                border-radius: 100%;
                padding: 0.31em;
            }
            .myact {
                @include respond(phone) {
                    display: none;
                }
            }
        }
        .dropdown-menu {
            .dropdown-item {
                font-size: 1.6em;
            }
        }
        .head-cart {
            font-size: 3.3rem;
        }
    }
}

.main-content {
    @include respond(phone) {
        margin-left: 1.4em !important;
        margin-right: -1em;
    }
}

.logout-dropdown {
    z-index: 999 !important;
}