.form-control {
  width: 40vw;
}

.c-success {
  background-color: rgb(103, 161, 2) !important;
  color: white !important;
}

.c-btn {
  width: 40vw;
  height: 7vh;
}

.c-btn-lg {
  width: 10vw;
  height: 7vh;
  padding: 3px;
}

.c-btn-primary {
  background-color: #082564;
  color: white;
}

.c-text-success {
  /* background-color: rgb(103,161,2) !important; */
  color: rgb(103, 161, 2) !important;
}

.c-btn-success {
  background-color: rgb(103, 161, 2) !important;
  color: white !important;
}

.c-btn-primary {
  background-color: #082564;
  color: white;
}

.c-text-success {
  /* background-color: rgb(103,161,2) !important; */
  color: rgb(103, 161, 2) !important;
}

a {
  text-decoration: none !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  width: 100%;
  padding: 8px;
}

.c-btn-lg {
  width: 10vw;
  height: 7vh;
  padding: 3px;
}

.c-btn-primary {
  background-color: #082564;
  color: white;
}

.c-text-success {
  /* background-color: rgb(103,161,2) !important; */
  color: rgb(103, 161, 2) !important;
}

.c-btn-success {
  background-color: rgb(103, 161, 2) !important;
  color: white !important;
}

.error-message {
  color: red;
}

.check-container {
  display: flex;
  flex-direction: row;
}

.register {
  height: 100vh;
  width: 100vh;
  background: #ffffff;
  overflow: hidden;
}

.bg-img {
  object-fit: scale-down;
}

.stepper {
  display: flex;
  width: 200px;
}

.step {
  border-radius: 50%;
  background: linear-gradient(180deg, #f4730b 0%, #f94501 100%);
}

.step-active {
  height: 50px;
  width: 50px;
  color: #ffffff;
}

.step-inactive {
  height: 20px;
  width: 20px;
}
/* Steps  */
.rounded-indicator {
  width: 32px;
  height: 32px;
  padding: 5px 0 5px 10px;
  font-size: 16px;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  float: left;
  cursor: pointer;
}
.active-step {
  margin-left: 8px;
  background: linear-gradient(90deg, #6dab00 34.8%, #619603 100.11%);
}
.inactive-step {
  background: #ffb74b;
  color: #ffb74b;
  font-size: 0;
  width: 12px;
  height: 12px;
  margin-top: 8px;
  margin-left: 8px;
}

.sub-header-4 h4 {
  color: #256c34;
}

body {
  font-family: Nunito, Arial, Helvetica;
  margin: 0;
}

h1 {
  font-size: 35px;
  font-weight: bolder;
  color: #256c34;
}

h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #256c34;
}

h3 {
  font-size: 1.8rem;
  font-weight: normal;
  color: #ffffff;
}

h4 {
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
}

h6 {
  font-size: 1rem;
  font-weight: bold;
  color: black;
}

h5 {
  font-size: 1.8rem;
  font-weight: normal;
  color: black;
}

p {
  font-size: 1rem;
  font-weight: normal;
  color: black;
}

/* Bootstrap Customizations */

.txt-primary {
  color: #256c34;
  font-size: 1.2rem;
  font-weight: bold;
}

button.btn-primary {
  background-color: #6dab00;
  border: none;
  padding: 12px;
}

button.btn-outline-primary {
  border-color: #6dab00;
  color: #6dab00;
  padding: 12px;
}

button.btn-outline-primary:hover {
  background-color: #6dab00;
  border: none;
  color: #fff;
  padding: 12px;
}

label.btn-info {
  background-color: #2e008e;
  border-color: #2e008e;
}

button.btn-info {
  background-color: #2e008e;
  border-color: #2e008e;
}

#create-new-role-btn {
  margin-top: 1.9em;
}

.radio-btn {
  display: block;
}

.radio-label {
  display: inline;
}

button.btn-primary:hover {
  background-color: #6dab00;
}

button.btn-secondary {
  background-color: #256c34;
  border: none;
  padding: 12px;
}

button.btn-secondary:hover {
  background-color: #256c34;
}

/* Step Content  */

.form-container {
  padding-right: 50px;
}

/* Step Form */

.form-content-container {
  margin: 20px 30px;
}

/* Content Header */

div.card-header {
  background-color: #fff;
  padding: 16px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.125);
}

div.card {
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.strong {
  font-weight: bolder;
}

.MuiButton-outlined .MuiButton-label {
  color: #256c34;
}

.MuiButton-outlinedPrimary {
  padding: 14px;
}

.MuiGrid-grid-xs-10 {
  margin-bottom: 10px;
}

.MuiCardHeader-title {
  font-size: 1rem !important;
  font-weight: bold;
}

.MuiCardHeader-root {
  padding-bottom: 0 !important;
  padding-left: 15px !important;
}

/* trash icon*/

.bi-trash {
  color: red;
  float: right;
}

.panel {
  background-color: white;
  width: 100%;
}

#mobile-view {
  margin: auto;
  width: 100%;
}

.add-btn[aria-expanded='true'] {
  background-color: #6dab00 !important;
  color: white !important;
}

.add-btn[aria-expanded='false'] {
  background-color: #256c34 !important;
  color: white !important;
}

.add-btn:after {
  content: '\003E';
  color: white;
  font-size: 13pt;
}

.add-btn[aria-expanded='false']:after {
  content: '\25BA';
  color: white;
  font-size: 13pt;
}

.add-btn[aria-expanded='true']:after {
  content: '\25BC';
  color: white;
  font-size: 13pt;
}

.form-control {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .add-btn {
    margin-bottom: 2px;
  }
}
