.login-card {
    margin: 8em auto;
    padding: 2em;
    border-radius: 3em;
    input {
        font-size: 1.1em;
    }
    button {
        font-size: 1.1em;
    }
    h3 {
        color: #256C34;
        font-size: 1.5em;
        font-weight: 800;
        text-align: center;
    }
}