/*
  
   
    #ffb74b
    
*/

// Colors
$color-primary: #256c34;
$color-primary-2: #5e8913;
$color-primary-light: #639903;
$color-primary-light-2: #b8df74;
$color-primary-light-3: #6dab00;
$color-dark: #303030;
$color-dark-2: #313131;
$color-secondary: #082564;
$color-secondary-light: #2e008e;
$color-secondary-light-2: #0052ce;
$color-tertiary: #ffb74b;
$color-tertiary-light: #eeb54c;
$color-grey-light-1: #f2f2f2;
$color-grey-light-2: #f2f3f7;
$color-grey-light-3: #fbfbfb;
$color-grey-dark-1: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;
$color-grey-dark-4:#6C6F7F;
$color-grey: #9fa2b8;
$color-white: #fff;
$color-blue: #0052ce;
$color-black: #000;
$color-gold: #fab428;
$color-red: #f21b1b;
$color-social: #173460;
$default-font-size: 1.6rem;
// Grey Line below
$grey-line: 1px solid $color-grey-light-2;