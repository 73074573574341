.list-user {
    h4 {
        color: #256C34;
        font-size: 1.5em;
        font-weight: 800;
        margin-bottom: -10px;
    }
    i {
        font-size: 2em;
    }
    .btn-group {
        button {
            font-size: 1em !important;
        }
    }
    .dropdown-menu {
        .dropdown-item {
            font-size: 1.5em;
        }
    }
    input {
        font-size: 1em;
    }
}

.create-user {
    #m-input {
        height: 2em;
        font-size: 1em;
    }
    button {
        font-size: 1em;
    }
    .head {
        font-size: 1.3em;
    }
    #m-textarea {
        font-size: 1em;
    }
}

.view-user {
    h4 {
        color: #256C34;
        font-size: 1.5em;
        font-weight: 800;
        margin-bottom: -10px;
    }
    label {
        font-size: 1.2em;
    }
    p {
        font-size: 1em;
    }
}