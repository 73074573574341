.overview {
    background-color: #F2F9EB;
    border-radius: .5em;
    padding: 2em;
    .product-card {
        background-color: white;
        padding: .7em;
        border-radius: .5em;
        .product-name {
            font-size: 1em;
            color: #A0B1C4;
        }
        .link {
            color: #A0B1C4;
            font-size: .8em;
            &:hover {
                cursor: pointer;
            }
        }
        .demand {
            font-size: 1.002em;
        }
    }
    .m-single {
        background-color: white;
        width: 100%;
        border-radius: .5em;
        padding: .7em;
        height: 6em;
        &.click {
            cursor: pointer;
        }
        .report {
            p {
                font-size: 1.7rem;
                &:nth-of-type(1) {
                    color: #A0B1C4;
                }
            }
        }
    }
}