    // mixins
    @mixin card-head() {
        .card {
            .card-header {
                p:nth-of-type(1) {
                    float: left;
                    color: #256C34;
                    font-weight: 700;
                }
                p:nth-of-type(2) {
                    float: right;
                    color: #FFC062;
                    font-weight: 700;
                }
            }
            @content;
        }
    }
    
    // Setup Store
    .setup-store {
        .main-header {
            h2 {
                color: $color-primary;
                font-size: 1.5em;
                font-weight: 800;
                margin-bottom: -10px;
            }
        }
        .sub-header-4 {
            h4 {
                font-size: 1.5rem;
            }
        }
        select {
            font-size: 1em;
            &>option {
                color: black;
            }
        }
        input {
            font-size: 1em;
        }
        button {
            font-size: 1em;
        }
        textarea {
            font-size: 1em;
        }
    }
    
    // List Store Table
    .list-store {
        .btn-group {
            button {
                font-size: 1em !important;
            }
        }
        .dropdown-menu {
            .dropdown-item {
                font-size: 1.5em;
            }
        }
        input {
            font-size: 1em;
        }
    }
    
    .store-detail {
        h4 {
            color: #256C34;
            font-size: 1.5em;
            font-weight: 800;
            margin-bottom: -10px;
        }
        h3 {
            color: rgb(92, 91, 91);
        }
    }
    
    // Company Profile
    div.card-header {
        background-color: #fff;
    }
    
    .add-more-fields {
        background: grey;
        color: white;
        margin: auto !important;
        margin-right: 30px !important;
        margin: 15px 30px 15px !important;
        &:hover {
            background: rgb(173, 172, 172);
            color: white;
        }
    }
    
    .fa-times {
        cursor: pointer;
    }
    
    .year-picker {
        z-index: 999;
    }
    
    // Company Profile
    .company-profile {
        h4 {
            font-size: 1.5rem;
        }
        p {
            font-size: 1.5rem;
        }
    }
    
    // Production Capacity
    .production-capacity {
        h4 {
            font-size: 1.5rem;
        }
        p {
            font-size: 1.5rem;
        }
    }
    
    // Production Certificate
    .production-certificate {
        h4 {
            font-size: 1.5rem;
        }
        p {
            font-size: 1.5rem;
        }
    }
    
    // Trade Association
    .trade-association {
        h4 {
            font-size: 1.5rem;
        }
        p {
            font-size: 1.5rem;
        }
    }
    
    // List Stores
    .list-store {
        h4 {
            color: #256C34;
            font-size: 1.5em;
            font-weight: 800;
            margin-bottom: -10px;
        }
        .create-btn {
            line-height: 2;
        }
    }
    
    // View Store
    .view-store {
        p {
            font-size: 1.5rem;
        }
        .content {
            padding-left: 30px;
            padding-bottom: 30px;
            padding-top: 20px;
            @include respond(phone) {
                padding: 10px;
                margin-left: 30px;
            }
            .head {
                color: #256C34;
                font-size: 1.5em;
                font-weight: 800;
                margin-bottom: 10px;
                margin-top: 30px;
            }
            .text {
                color: #8BD41E;
                font-weight: 600;
            }
            .amount {
                color: #256C34;
                font-size: 1.5em;
                font-weight: 600;
                margin-top: 40px;
                margin-bottom: 40px;
            }
            .img {
                width: 130px;
                height: 130px;
                margin-top: 30px;
                margin-bottom: 10px;
            }
            .txt {
                font-size: 1em;
                font-weight: 600;
            }
            .account {
                width: 100%;
                height: 20em;
                background-image: url(../../assets/img/account.png);
                border-radius: 4px;
                // backgroundColor: 'green',
                background-repeat: no-repeat;
                background-size: auto;
                position: relative;
                @include respond(phone) {
                    background-image: url(../../assets/img/account.png);
                    background-size: contain;
                }
                @include respond(tab-port) {
                    background-image: url(../../assets/img/account.png);
                    background-size: contain;
                }
                .acct {
                    position: absolute;
                    color: white;
                    right: 35px;
                    top: 20px;
                    width: 60%;
                    @include respond(phone) {
                        right: 0px !important;
                        top: 30px;
                        width: 65%;
                    }
                    @include respond(tab-port) {
                        right: 0px !important;
                        width: 60%;
                    }
                    p:nth-of-type(1) {
                        color: white;
                        font-size: 1.1em;
                        font-weight: 700;
                    }
                    button {
                        background-color: white;
                        color: #256C34;
                        border: none;
                        padding: 15px;
                        border-radius: 10px;
                        &:hover {
                            background: #256C34;
                            color: "white";
                        }
                        &:focus {
                            outline: "none";
                        }
                        @include respond(phone) {
                            padding: 10px;
                            width: 90%;
                            font-size: 10pt;
                        }
                        @include respond(tab-port) {
                            padding: 10px;
                            width: 90%;
                            font-size: 8pt;
                        }
                    }
                }
            }
            .new-product {
                @include card-head();
            }
            .order-request {
                @include card-head();
            }
            .notification {
                @include card-head() {
                    .arrow {
                        font-size: 2em;
                    }
                }
            }
            .my-branches {
                @include card-head();
            }
            .my-staff {
                @include card-head();
            }
            .product-stats {
                .product-stats-item {
                    background-color: #F2F3F7;
                    width: 90%;
                    margin-bottom: 20px;
                    border-radius: 2px;
                    .bgicon {
                        background-color: #A6E13C;
                        img {
                            margin-top: 10px;
                        }
                    }
                    p {
                        margin-left: 10px;
                        padding-top: 10px;
                        color: #256C34;
                        font-weight: 500;
                        width: 100%;
                        span {
                            font-weight: 700;
                            font-size: 1.3em;
                            color: #256C34;
                        }
                    }
                }
            }
            .product-item {
                text-align: center;
                img.default_img {
                    width: 150px;
                    height: 150px;
                    border: 1px solid #dee2e6;
                    background-color: #fff;
                    border-radius: 0.25rem;
                    padding: 0.25rem
                }
                img {
                    width: 150px;
                    height: 150px;
                }
            }
        }
    }
    
    .view-stores {
        p {
            font-size: 1.4em;
        }
        h4 {
            color: #256C34;
            font-size: 1.5em;
            font-weight: 800;
            margin-bottom: -10px;
        }
        button {
            font-size: 1em;
        }
    }
    
    .create-user-store {
        h4 {
            color: #256C34;
            font-size: 1.5em;
            font-weight: 800;
            margin-bottom: -10px;
        }
        p {
            font-size: 1.4em;
        }
        input {
            font-size: 1em;
        }
        textarea {
            font-size: 1em;
        }
    }