.m-card {
  background-color: $color-white;
  box-shadow: 0 2rem 4rem rgba($color-grey-dark-1, 0.05);
  padding: 0;
  border-radius: 1rem;
  overflow: hidden;
}

.m-card-outline {
  background-color: $color-white;
  box-shadow: 0 2rem 4rem rgba($color-grey-dark-1, 0.05);
  padding: 0;
  border: $grey-line;
  border-radius: 1rem;
  overflow: hidden;
}

.m-card-header {
  width: 100%;
  padding: 2rem 3rem;
  border-bottom: $grey-line;
}

.m-card-body {
  margin: 1rem 2rem;
  padding: 2rem;
}
